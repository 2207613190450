.popbox.visible {
	opacity: 1;
	z-index: 999;
	transition: opacity 0.8s ease;
}
.popbox.opened {
	display: block;
}

.popbox_locked .popbox_main_content.popbox_blur {
	filter: blur(15px);
}

html.popbox_locked {
	overflow: hidden;
	-ms-touch-action: none;
	touch-action: none;
}

.popbox {
	z-index: -999;
	position: fixed;
	width: 100%;
	max-width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	will-change: opacity;
	transition: opacity 0.3s ease;
	opacity: 0;
	will-change: opacity;
	background: rgba(60, 52, 66, 0.7);
	padding: 18px;
	padding-top: 5%;
	overflow-y: auto;
	display: none;
}

.popbox_container {
	border-radius: 5px;
	margin-bottom: 18px;
	padding: 18px;
	margin-left: auto;
	margin-right: auto;
	background: #fff;
	max-width: 400px;
}

.wide-container {
	width: 90%;
	max-width: 960px;
}

.popbox {
	h5 {
		margin-top: 0;
	}
	a {
		color: $color-purple;
		transition: all 0.4s;
		&:hover {
			color: $color-pink;
		}
	}
}
