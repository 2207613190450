// Shake Animation
@keyframes shake {
	10%,
	90%,
	100% {
		transform: rotate(-2deg) scale(0);
	}

	20%,
	80% {
		transform: rotate(2deg);
	}

	30%,
	50%,
	70% {
		transform: rotate(-2deg) scale(1.2);
	}

	40%,
	60% {
		transform: rotate(2deg) scale(1.1);
	}
}

.animate__animated.animate__shake {
	animation: shake 8s linear infinite;
	// animation-timing-function: ease-in-out;
	animation-fill-mode: forwards;
}

// Pulse Animation
@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(39, 14, 35, 0.15);
	}

	100% {
		box-shadow: 0 0 0 35px rgba(39, 14, 35, 0);
	}
}

// Wide Pulse Animation
@keyframes wide-pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(39, 14, 35, 0.15);
	}

	100% {
		box-shadow: 0 0 0 100px rgba(39, 14, 35, 0);
	}
}

/* Fly in and out - Paperplane ---------------------------------------------- */
@keyframes flyInOutIn {
	0% {
		opacity: 0;
		transform: translate3d(-100vw, 0, 0) scale(0.1);
	}
	50% {
		opacity: 1;
		transform: translate3d(100vw, 0, 0) scale(1);
	}
	51% {
		opacity: 1;
		transform: translate3d(100vw, 0, 0) scaleX(-1);
	}
	100% {
		opacity: 1;
		transform: translateZ(0) scaleX(-1);
	}
}
.animate__animated.animate__flyinoutin {
	animation-name: flyInOutIn;
	animation-duration: 4s !important;
	animation-timing-function: ease-out !important;
}

/* Rotate forever ----------------------------------------------------------- */
@keyframes rotation {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
.animate__animated.animate__rotate {
	animation-name: rotation;
	animation-duration: 12s !important;
	animation-timing-function: linear !important;
	animation-iteration-count: infinite;
}

/* Orbit around center ------------------------------------------------------ */
@keyframes orbit {
	from {
		transform: rotate(0deg) translateX(280px) rotate(0deg);
		opacity: 1;
	}
	to {
		transform: rotate(360deg) translateX(280px) rotate(-360deg);
		opacity: 1;
	}
}

.animate__animated.animate__orbit {
	animation: orbit 24s linear infinite;
	animation-fill-mode: forwards;
}

/* Plopp around center ------------------------------------------------------ */
@keyframes plopp {
	from {
		transform: rotate(0deg) translateX(280px) rotate(0deg);
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

.animate__animated.animate__plopp {
	animation: plopp 1s linear infinite;
	animation-fill-mode: forwards;
}
