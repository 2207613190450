@use "sass:math"; // needed since gulp-sass 5.0
/****************************************************************************************
							FONT VARS
*****************************************************************************************/

$font-head-bold: "NameSansHeading-Bold";
$font-head-light: "NameSansHeading-Light";
$font-head-reg: "NameSansHeading-Regular";

$font-text-black: "NameSanstext-Black";
$font-text-bold: "NameSanstext-Bold";
$font-text-med: "NameSanstext-Medium";
$font-text-reg: "NameSanstext-Regular";

/****************************************************************************************
							COLOR VARS
*****************************************************************************************/

$color-pink: #f08481;
$color-purple: #270e23;

$color-white: #fff;
$color-black: #000;

/****************************************************************************************
							BREAKPOINT MIXIN
*****************************************************************************************/

// BREAKPOINTS
$size-xxl: 1600px;
$size-xl: 1280px;
$size-lg: 960px;
$size-md: 840px;
$size-sm: 600px;
$size-xs: 420px;

@mixin MQ($canvas) {
	@if $canvas==XXL {
		@media only screen and (max-width: $size-xxl) {
			@content;
		}
	}
	@if $canvas==XL {
		@media only screen and (max-width: $size-xl) {
			@content;
		}
	}
	@if $canvas==LG {
		@media only screen and (max-width: $size-lg) {
			@content;
		}
	}
	@if $canvas==MD {
		@media only screen and (max-width: $size-md) {
			@content;
		}
	} @else if $canvas==SM {
		@media only screen and (max-width: $size-sm) {
			@content;
		}
	} @else if $canvas==XS {
		@media only screen and (max-width: $size-xs) {
			@content;
		}
	}
}

/****************************************************************************************
							COLOR MIXIN
*****************************************************************************************/

@mixin alpha-attribute($attribute, $color, $background) {
	$percent: alpha($color) * 100%;
	$opaque: opacify($color, 1);
	$solid-color: mix($opaque, $background, $percent);
	#{$attribute}: $solid-color;
	#{$attribute}: $color;
}

/****************************************************************************************
							FLUID TYPE MIXIN
*****************************************************************************************/

@function strip-unit($value) {
	@return math.div($value, $value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
	$u1: unit($min-vw);
	$u2: unit($max-vw);
	$u3: unit($min-font-size);
	$u4: unit($max-font-size);

	@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {
		& {
			font-size: $min-font-size;
			@media screen and (min-width: $min-vw) {
				font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
			}
			@media screen and (min-width: $max-vw) {
				font-size: $max-font-size;
			}
		}
	}
}

// Defaults
$min_width: 320px;
$max_width: 1200px;
$min_font: 16px;
$max_font: 24px;

///		EXAMPLE:
///		p.test {
///           @include fluid-type(360px, 1600px, 32px, 46px);
///			}
///     }
///     Example scales fonts between 1600px and 360px (viewport) from 46px to 32px (font-size)

%fontsize-head-tiny {
	@include fluid-type(360px, 1600px, 18px, 22px);
}
%fontsize-head-small {
	@include fluid-type(360px, 1600px, 18px, 26px);
}
%fontsize-head-med {
	@include fluid-type(360px, 1600px, 22px, 32px);
}
%fontsize-head-big {
	@include fluid-type(360px, 1600px, 32px, 56px);
}
%fontsize-head-huge {
	@include fluid-type(360px, 1600px, 32px, 56px);
}

%fontsize-text-small {
	@include fluid-type(360px, 1600px, 16px, 18px);
}
%fontsize-text-med {
	@include fluid-type(360px, 1600px, 16px, 18px);
}
%fontsize-text-big {
	@include fluid-type(360px, 1600px, 32px, 56px);
}
