html {
	height: 100%;
	width: 100%;
}

body {
	background: $color-pink;
	color: $color-purple;
	font-family: "Name Sans", Tahoma, Geneva, Verdana, sans-serif;
	height: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
}

p,
ul,
li {
	@extend %fontsize-text-med;
	line-height: 1.3;
}

b,
strong {
	font-variation-settings: "wght" 600;
	font-weight: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-variation-settings: "wght" 700;
	font-weight: normal;
}

h1 {
	@extend %fontsize-head-huge;
}

h2 {
	@extend %fontsize-head-big;
}

h3 {
	@extend %fontsize-head-med;
}

h4,
h5,
h6 {
	@extend %fontsize-head-small;
}

/****************************************************************************************
							SCREEN SIZE WARNING
*****************************************************************************************/

.screen-size-warning {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	background: $color-pink;
	opacity: 0;
	transition: opacity 0.4s;
	z-index: 999;
	justify-content: center;
	align-items: center;
	text-align: center;
	display: none;
	> * {
		max-width: 90%;
	}
}

@media (max-width: 960px) {
	.screen-size-warning {
		display: flex;
		flex-direction: column;
		opacity: 1;
	}
}
@media (orientation: portrait) {
	.screen-size-warning {
		display: flex;
		opacity: 1;
	}
}

/****************************************************************************************
							Frontpage
*****************************************************************************************/
h1.main-title {
	@extend %fontsize-head-huge;
	letter-spacing: 2px;
	margin-bottom: 0;
	text-transform: uppercase;
}

.med-text {
	@extend %fontsize-head-small;
	font-variation-settings: "wght" 500;
}

.flex-center {
	display: flex;
	img {
		margin-left: 10px;
	}
}

.imprint-toggle {
	align-items: center;
	background: $color-white;
	border: none;
	border-radius: 50px;
	cursor: pointer;
	display: flex;
	font-size: 24px;
	font-variation-settings: "wght" 600;
	height: 35px;
	justify-content: center;
	padding: 20px;
	position: absolute;
	right: 25px;
	top: 20px;
	transition: all 0.4s;
	width: 35px;
	@include MQ(LG) {
		right: 10px;
	}

	&:hover {
		transform: scale(1.1) rotate(180deg);
	}
}

.start-button-container {
	flex-grow: 1;

	#start-button {
		align-items: center;
		animation: pulse 2s ease-in-out infinite;
		background: $color-white;
		border-color: transparent;
		border-radius: 50px;
		cursor: pointer;
		display: flex;
		height: 100px;
		justify-content: center;
		margin: 50px 0;
		transition: all 0.4s;
		width: 100px;

		svg {
			fill: $color-purple;
			height: 50px;
			margin-left: 10px;
			max-width: 30px;
		}

		&:hover {
			background: $color-purple;
			transform: scale(1.1);

			svg {
				fill: $color-white;
			}
		}
	}
}

.notice {
	background: rgba(255, 255, 255, 0.5);
	border-radius: 15px;
	bottom: 35px;
	font-size: 16px;
	padding: 15px 35px;
	@include MQ(LG) {
		border-radius: 25px;
	}

	p {
		margin: 0;
	}
}

.phone-number {
	position: absolute;
	bottom: 35px;
	p {
		@extend %fontsize-head-med;
	}
}

/****************************************************************************************
							AUDIO
*****************************************************************************************/
.audio,
.plyr--audio {
	display: none;
}

/****************************************************************************************
							SECTIONS
*****************************************************************************************/

.section {
	position: relative;
}

.wrap {
	height: 100%;
	width: 100%;

	&--center {
		align-items: center;
		display: flex;
		flex-direction: column;
		justify-content: center;
		text-align: center;
	}

	&--pad {
		padding: 25px;
		padding-top: 10%;
	}

	&--video {
		align-content: center;
		background: $color-black;
		display: flex;
		justify-content: center;
	}
}

/****************************************************************************************
							FULL VIDEO SLIDE
*****************************************************************************************/

.video-slide {
	background: $color-black;
}

.vid-container {
	height: auto;
	max-height: 100%;
	max-width: 100%;
	object-fit: cover;
	width: 100%;
	@media screen and (orientation: portrait) {
		object-fit: scale-down;
	}
}

/****************************************************************************************
							ILLUSTRATION ONLY
*****************************************************************************************/

img.illu {
	height: auto;
	max-width: 560px;
	max-height: 600px;
	object-fit: cover;

	@include MQ(LG) {
		max-height: 40%;
	}

	&:hover {
		// animation: shake 1s ease-in-out 1;
	}
}

.single-illu-wrap {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0 auto;
	max-height: 70%;

	img.illu {
		max-height: 80%;
		max-width: 720px;
		@include MQ(LG) {
			max-width: 90%;
		}
	}
}

.double-illu-wrap {
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	gap: 55px;
	justify-content: center;
	margin: 0 auto;
	max-width: 1440px;

	@include MQ(LG) {
		gap: 10px;
	}
}

.orbit-illu-wrap {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	align-content: center;
	overflow: hidden;
}

.triple-illu-wrap {
	align-content: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;
	max-height: 100vh;
	max-width: 90%;
	width: 1280px;

	a {
		display: flex;
		flex: 0 0 50%;
		justify-content: center;
		@include MQ(LG) {
			flex: 0 0 100%;
		}

		&:first-of-type {
			flex: 0 0 100%;
		}

		img {
			max-height: 380px;
			max-width: 420px;
			@include MQ(LG) {
				max-height: 250px;
				max-width: 90%;
			}
		}
	}
}

img.illu-backpack {
	position: absolute;
	max-width: 250px;
}

img.illu-boots {
	position: absolute;
	max-width: 150px;
	opacity: 0;
}

img.illu-pool {
	position: absolute;
	max-width: 300px;
}

img.illu-honey {
	max-width: 100%;
	transform: scale(0);
}

.pos {
	position: absolute;
	width: 100px;
	top: 0;
	left: 0;
}
.pos--1 {
	transform: translate3d(72vw, 10vh, 0);
}
.pos--2 {
	transform: translate3d(30vw, 70vh, 0);
}
.pos--3 {
	transform: translate3d(85vw, 40vh, 0);
}
.pos--4 {
	transform: translate3d(10vw, 75vh, 0);
}
.pos--5 {
	transform: translate3d(35vw, 15vh, 0);
}
.pos--6 {
	transform: translate3d(10vw, 10vh, 0);
}
.pos--7 {
	transform: translate3d(75vw, 70vh, 0);
}
.pos--8 {
	transform: translate3d(20vw, 40vh, 0);
}
.pos--9 {
	transform: translate3d(65vw, 45vh, 0);
}
/****************************************************************************************
						ANIMAL SELECTION PAGE
 *****************************************************************************************/

.wrap--animals {
	align-content: center;
	display: flex;
	flex-wrap: wrap;
	gap: 35px;
	justify-content: center;
	margin: 0 auto;
	max-width: 1440px;
	padding: 55px;
	@include MQ(LG) {
		gap: 25px;
		padding: 25px;
	}
}

.animal-card {
	display: block;
	flex: 0 1 300px;
	position: relative;
	background: white;
	border-radius: 10px;
	text-decoration: none;
	transition: all 0.3s;
	@include MQ(LG) {
		flex: 0 1 45%;
	}

	&:hover {
		transform: scale(1.1);
	}

	p {
		@extend %fontsize-head-small;
		font-variation-settings: "wght" 600;
		margin: 5px 15px 10px 15px;
		color: $color-purple;
	}

	&--image {
		border-radius: 10px 10px 0 0;
		height: auto;
		max-width: 320px;
		width: 100%;
	}
}

/****************************************************************************************
						3 ILLUSTRATIONS
 *****************************************************************************************/

.wrap-3-illus {
	align-content: center;
	align-items: center;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	margin: 0 auto;
	max-width: 1280px;
	padding: 15% 10%;
	width: 90%;

	@include MQ(LG) {
		gap: 25px;
		max-width: 60%;
	}

	.button {
		display: flex;
		flex: 0 1 45%;
		justify-content: center;
		@include MQ(MD) {
			flex-basis: 100%;
		}
	}

	.illu {
		height: auto;
		max-height: 45vh;
		max-width: 420px;
		@include MQ(LG) {
			height: auto;
			max-width: 100%;
		}
	}
}

/****************************************************************************************
						Applause Slider
 *****************************************************************************************/
.applauso-meter {
	width: 750px;
	max-width: 90%;
	margin: 0 auto;
	background: rgba(255, 255, 255, 0.5);
	border-radius: 100px;
	font-size: 16px;
	padding: 30px 25px;
	position: absolute;
	bottom: 35px;
	@include MQ(LG) {
		border-radius: 25px;
	}
}

input[type="range"] {
	outline: none;
	padding: 0;
	width: 100%;
	height: 25px;
	background-color: #270e23;
	border-radius: 25px;
	cursor: pointer;
	-webkit-appearance: none;
}

input[type="range"]::-webkit-slider-thumb {
	width: 50px;
	height: 50px;
	border: 0;
	background: #fff;
	border-radius: 100%;
	margin-left: -25px;
	-webkit-appearance: none;
}

input[type="range"]::-moz-range-thumb {
	width: 65px;
	height: 65px;
	border: 0;
	background: url(./img/clap.svg) center center no-repeat;
	background-color: #fff;
	border-radius: 100%;
	margin-left: -50px;
	transition: all 0.4s;
	&:hover {
		transform: scale(1.1);
	}
}

/****************************************************************************************
						CREDITS / CAST
 *****************************************************************************************/

.wrap--credits {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	justify-items: center;
	text-align: center;

	dl.cast-list {
		@extend %fontsize-head-small;
		max-width: 1024px;
		width: 90%;

		div {
			display: flex;
			margin: 5px 0;
		}

		dt {
			display: flex;
			flex: 1 1 auto;
			font-variation-settings: "wght" 600;
			margin: 0;
		}

		dt::after {
			border-bottom: 2px dotted $color-purple;
			content: "";
			flex: 1 1 auto;
			margin: 0 12px 5px;
		}

		dd {
			flex: 0 1 auto;
			margin: 0;
		}
	}

	.popup-button {
		color: $color-purple;
		background: none;
		border: none;
		padding: 0;
		text-decoration: underline;
		cursor: pointer;
		&:hover {
			opacity: 0.5;
		}
	}

	.logos {
		align-items: center;
		display: flex;
		gap: 55px;
		justify-content: center;
		margin-top: 55px;
		max-width: 720px;
		width: 90%;

		img {
			max-width: 100%;
		}
	}

	.colophon {
		@extend %fontsize-head-tiny;
		display: flex;
		gap: 25px;
		margin-top: 55px;

		a {
			color: $color-purple;
			opacity: 0.6;
			text-decoration: none;
			transition: all 0.4s;

			&:hover {
				opacity: 1;
				text-decoration: underline;
			}
		}
	}
}

.next-slide-button,
.end-fullscreen-button {
	align-items: center;
	background: $color-white;
	border: none;
	border-radius: 55px;
	bottom: 20px;
	cursor: pointer;
	display: flex;
	height: 45px;
	justify-content: center;
	position: absolute;
	right: 25px;
	transition: all 0.4s;
	width: 45px;
	z-index: 10;
	@include MQ(LG) {
		right: 10px;
	}

	&:hover {
		background: $color-purple;
		transform: scale(1.1);

		svg {
			fill: $color-white;
		}
	}

	svg {
		fill: $color-purple;
		max-height: 70%;
		max-width: 70%;
	}
}

/****************************************************************************************
					HELPER AND SPECIAL CLASSES
 *****************************************************************************************/
.desktop-only {
	@include MQ(LG) {
		display: none;
	}
}

#matter-wrap {
	// background: yellow;
	// height: 500px;
	// width: 500px;
	// background-color: #f5afad;
	height: 100%;
	width: 100%;
}

.mobile-only {
	display: none;
	@include MQ(LG) {
		display: static;
	}
}

.no-wrap {
	white-space: nowrap;
}

.hidden {
	display: none;
}

// SKIP TO CONTENT LINKS
.skip-to-content-link {
	background: $color-black;
	color: $color-white;
	font-family: $font-text-med;
	height: 30px;
	left: 50%;
	line-height: 1;
	padding: 5px 20px;
	position: absolute;
	transform: translateY(-1000%);
	transition: transform 0.3s;
	z-index: 5;
}

.skip-to-content-link:focus {
	transform: translateY(0%);
}
