/*!
Site Name: BOILERPLATE GULP STATIC
Author: jk
Author URI: https://www.jankoepsel.com
Version: 1.0
*/

/****************************************************************************************
                                NORMALIZE
*****************************************************************************************/
@import "partials/normalize";

/****************************************************************************************
                                MIXINS AND VARIABLES
*****************************************************************************************/
@import "partials/mixvars";

/****************************************************************************************
                                ECO PRINT CSS
*****************************************************************************************/
@import "partials/print";

/****************************************************************************************
                                FONTS
*****************************************************************************************/
@import "partials/fonts";

/****************************************************************************************
                                FULLPAGE STYLES
*****************************************************************************************/
@import "vendors/fullpage";

/****************************************************************************************
                                POPBOX STYLES
*****************************************************************************************/
@import "vendors/popbox";

/****************************************************************************************
                               ANIMATIONS
*****************************************************************************************/
@import "partials/animations";

/****************************************************************************************
                                ANIMATE CSS
*****************************************************************************************/
@import "vendors/animate";

/****************************************************************************************
                                MAIN STYLES
*****************************************************************************************/
@import "main";
